<template>
  <form @submit.prevent="handleSubmit" novalidate>
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'FormComponent',
  props: {},
  data() {
    return {
      inputTypes: ['InputComponent', 'PasswordInputComponent', 'TelInputComponent', 'CheckboxComponent', 'SelectComponent'],
    };
  },
  computed: {
    isValid() {
      return Object.values(this.inputs).every((input) => input);
    },
  },
  mounted() {},
  methods: {
    submit() {
      this.handleSubmit();
    },
    handleSubmit() {
      const children = this.$children.filter(
        (child) => this.inputTypes.includes(child.$options.name),
      );

      children.forEach((child) => {
        child.checkValidation();
      });

      if (children.every((child) => child.isValid)) {
        this.$emit('submit');
      }
    },
  },
};
</script>
